.login-page {
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;

    .logo-wrapper {
      width: fit-content;
      margin: 32px 0 24px;
      cursor: pointer;
    }

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px 22px 26px;

      max-width: 380px;

      background: #ffffff;
      box-shadow: -4px 10px 30px rgba(137, 137, 137, 0.16);
      border-radius: 16px;
    }
  }
}

.recaptcha {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
