@import '../../../../styles/palette';

.disabled {
  padding: 250px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.text {
  color: $color-text;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
}

.button {
  margin-top: 15px;
  padding: 1px 20px;
  height: 44px;
  border-radius: 6px;
  background-color: $color-primary;
  border-color: $color-primary;
  color: $white-color;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}
