@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.FSlogo {
  width: 130px;
}

.logo {
  width: 96px;
  height: 16px;
}

.frameHeader {
  font-family: $font-family-main;
  margin-top: 10px;
  font-weight: 700;
  font-size: 22px;
  color: $color-text;

  @include responsive(500px) {
    margin-top: 8px;
    font-weight: 600;
    font-size: 18px;
  }

  @include responsive(330px) {
    margin-top: 4px;
  }
}
