@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.payMethod {
  margin-top: 10px;

  @include responsive(330px) {
    margin-top: 8px;
  }
}

.payLabel {
  @include responsive(330px) {
    font-size: 14px;
  }
}

.customRadio {
  margin-top: 5px;
  height: 30px;
  display: flex;
  gap: 110px;
  align-items: center;
  color: $color-text;

  @include responsive(400px) {
    gap: 0;
    justify-content: space-between;
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      padding-left: 40px;
      cursor: pointer;

      @include responsive(330px) {
        padding-left: 30px;
      }

      &::before {
        content: '';
        background: $white-color;
        border: 2px solid $color-primary;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        left: 0;

        @include responsive(330px) {
          height: 15px;
          width: 15px;
        }
      }

      &::after {
        content: '';
        background: $color-primary;
        width: 13.5px;
        height: 13.5px;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 5px;
        opacity: 0;
        transform: scale(2);

        @include responsive(330px) {
          height: 9px;
          width: 9px;
        }
      }
    }

    &:checked + label::after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.rusCards,
.engCards {
  margin-top: 12px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;

  @include responsive(400px) {
    gap: 15px;
  }

  @include responsive(330px) {
    gap: 8px;
  }
}

.form_radio_btn {
  display: inline-block;
  height: 44px;

  input[type='radio'] {
    opacity: 0;
    position: relative;
    left: 35px;
    top: 10px;
  }

  label {
    width: 95px;
    height: 42px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    gap: 4px;
    line-height: 41px;
    border: 2px solid $color-light-gray;
    border-radius: 6px;
    user-select: none;
    position: relative;
    top: -25px;

    @include responsive(400px) {
      width: 80px;
      height: 40px;
    }

    @include responsive(320px) {
      width: 75px;
      height: 38px;
    }

    &:hover {
      color: #666;
      border-color: $color-primary;
    }
  }

  .visaMasterIcon {
    width: 52px;
    height: 22px;
    @include responsive(400px) {
      width: 45px;
      height: 19px;
    }

    @include responsive(320px) {
      width: 42px;
      height: 17px;
    }
  }

  .mirIcon {
    width: 36px;
    height: 12px;

    @include responsive(400px) {
      width: 30px;
      height: 10px;
    }

    @include responsive(320px) {
      width: 28px;
      height: 10px;
    }
  }

  .visaMasterIconEn {
    width: 76px;
    height: 30px;
  }

  input[type='radio']:checked + label {
    border: 2px solid $color-primary;
  }

  input[type='radio']:disabled + label {
    color: #666;
    border-color: #efefef;
  }
}
