.login-page {
  width: 100%;
  .container {
    display: flex;
    flex-direction: column;

    .form-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      max-width: 380px;

      border-radius: 16px;

      .header {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin-bottom: 10px;
        }

        .subtitle {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          margin: 28px 0;
          a {
            text-decoration: none;
            color: #4285f4;
          }
        }
      }

      .form-fields {
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-width: 332px;
        width: 100%;
        margin-top: 28px;

        .input {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 12px;
          gap: 4px;

          width: 100%;
          max-width: 310px;
          height: 40px;

          background: #f7f7f7;
          border-radius: 8px;
          border: none;
          outline: none;

          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #242424;

          &:focus {
            background: #ffffff;
            box-shadow: 0px 0px 5px rgba(101, 83, 245, 0.3);
          }
          &.invalid {
            color: #f84708;
            background: rgba(248, 71, 8, 0.08);
            box-shadow: unset;
          }
          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #898989;
          }
        }

        .phone-input-wrapper {
          margin-bottom: 28px;
          .phone-input {
            &.invalid {
              background: rgba(248, 71, 8, 0.08);
              box-shadow: unset;
              input {
                color: #f84708;
              }
            }
          }

          .recaptcha {
            width: 100%;
            margin-top: 16px;
            transform: scale(0.85);
            transform-origin: 0 0;
          }
        }

        .password-input-wrapper {
          position: relative;

          .show-password-icon {
            position: absolute;
            top: 20px;
            right: 12px;
            cursor: pointer;
          }
        }

        .code-invalid {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          margin-top: 8px;
        }
      }

      .form-footer {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;

        width: 100%;

        margin-top: 14px;
        margin-bottom: 34px;

        .form-footer-top {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          width: 100%;

          .remember {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
          }
          .forgot-password {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-decoration: none;
            cursor: pointer;
          }
        }
        .personal-info {
          color: #898989;
        }
      }

      .button-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }

      .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;

        margin-top: 30px;

        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        .to-signup-btn {
          cursor: pointer;
          text-decoration: underline;
          color: #4285f4;
        }
      }
    }
  }
}
