@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}


.title {
  margin-top: 10px;
  font-size: 15px;
  line-height: 22px;
  color: $color-text;
  font-family: $font-family-main;

  @include responsive(500px) {
    margin-top: 8px;
    font-size: 14px;
  }
}

.boldTitle {
  font-weight: 700;
  line-height: 22px;
  color: $color-secondary;
}

.descriptionFS {
  line-height: 22px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  font-family: $font-family-main;
}