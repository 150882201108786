@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.productsTitle {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include responsive(330px) {
    margin: 7px 0;
  }
}

.prodAmount {
  font-family: $font-family-main;
  font-weight: 700;
  font-size: 22px;
  color: $color-text;

  @include responsive(500px) {
    font-size: 18px;
    font-weight: 600;
  }

  @include responsive(330px) {
    font-size: 16px;
    font-weight: 600;
  }
}

.showProdBtn {
  font-family: $font-family-main;
  font-weight: 500;
  font-size: 14px;
  text-decoration-line: underline;
  color: $color-muted;
  cursor: pointer;

  @include responsive(500px) {
    font-size: 12px;
  }

  @include responsive(330px) {
    font-size: 11px;
  }
}

.productsRow {
  display: flex;
  gap: 25px;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;

  @include responsive(330px) {
    margin-top: 7px;
  }
}

.frameProducts {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.number {
  font-family: $font-family-main;
  color: $color-muted;
  margin-top: -1px;

  @include responsive(500px) {
    font-size: 13px;
  }

  @include responsive(330px) {
    font-size: 12px;
  }
}

.frameProductTitle {
  font-family: $font-family-main;
  font-weight: 700;
  font-size: 16px;
  color: $color-text;
  line-height: 125%;

  @include responsive(500px) {
    font-weight: 600;
    font-size: 13px;
  }

  @include responsive(330px) {
    font-size: 12px;
  }
}

.frameProductDescription {
  font-family: $font-family-main;
  font-weight: 400;
  font-size: 14px;
  color: $color-muted;
  line-height: 125%;
  margin-top: 8px;

  @include responsive(500px) {
    font-size: 12px;
    margin-top: 6px;
  }

  @include responsive(330px) {
    font-size: 11px;
  }
}


.frameProdPrice {
  font-family: $font-family-main;
  font-weight: 700;
  font-size: 16px;
  color: $color-text;
  line-height: 125%;
  white-space: nowrap;
  text-align: end;

  @include responsive(500px) {
    font-weight: 600;
    font-size: 14px;
  }

  @include responsive(330px) {
    font-size: 12px;
  }
}

.frameOldPrice {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-family: $font-family-main;
  margin-top: 8px;

  @include responsive(500px) {
    margin-top: 6px;
  }
}

.oldPrice {
  font-weight: 600;
  font-size: 14px;
  color: $color-muted;
  white-space: nowrap;
  text-decoration: none;
  position: relative;

  &:before {
    content: '';
    border-bottom: 1.5px solid $color-muted;
    position: absolute;
    width: 95%;
    height: 50%;
    transform: rotate(10deg);
  }

  @include responsive(500px) {
    font-size: 12px;
  }

  @include responsive(330px) {
    font-size: 11px;
  }
}

.priceDifference {
  color: $white-color;
  font-weight: 600;
  font-size: 10px;
  padding: 2px 4px;
  background: #9b8eff;
  border-radius: 90px;
  height: 15px;
  white-space: nowrap;

  @include responsive(500px) {
    font-size: 9px;
    height: 14px;
  }

  @include responsive(330px) {
    font-size: 8px;
    height: 13px;
  }
}

.line {
  border-bottom: 1px solid #eeeeee;
  margin-top: 10px;

  @include responsive(330px) {
    margin-top: 7px;
  }
}

.emptyLine {
  border-bottom: 1px solid #eeeeee;
  margin: 32px 0 23px 0;
}

.priceRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  @include responsive(330px) {
    margin-top: 7px;
  }
}

.price {
  display: flex;
  align-items: center;
  gap: 16px;

  @include responsive(500px) {
    gap: 8px;
  }
}

.sum {
  font-family: $font-family-main;
  font-weight: 500;
  font-size: 14px;
  color: #383838;

  @include responsive(500px) {
    font-size: 13px;
  }

  @include responsive(320px) {
    font-size: 12px;
  }
}

.sumPrice {
  font-family: $font-family-main;
  font-weight: 700;
  font-size: 28px;
  color: $color-text;

  @include responsive(500px) {
    font-size: 18px;
  }

  @include responsive(320px) {
    font-size: 16px;
  }
}

.sumPromo {
  font-family: $font-family-main;
}

.oldSum {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @include responsive(500px) {
    gap: 8px;
  }
}

.saleSum,
.sale {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &.sale {
    margin-top: 8px;
    gap: 20px;
    justify-content: space-between;

    @include responsive(500px) {
      gap: 10px;
      margin-top: 2px;
    }
  }

  &.saleSum {
    gap: 10px;
  }
}

.salePrice {
  font-weight: 600;
  font-size: 14px;
  color: $color-secondary;

  @include responsive(500px) {
    font-size: 12px;
  }

  @include responsive(330px) {
    font-size: 11px;
  }
}