.modal {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}
.active_modal {
  opacity: 1;
  overflow: auto;
}
.modal_content {
  max-width: fit-content;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(128, 128, 128, 0.15);
  border-radius: 12px;
  transform: scale(0.5);
  transition: 0.4s all;
  margin: 10px;
}
.modal_content.active {
  transform: scale(1);
}
