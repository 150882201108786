@import '../../styles/palette';

@mixin font-style($weight, $size, $color: $color-text) {
  font-family: $font-family-main;
  font-weight: $weight;
  font-size: $size;
  color: $color;
}

@mixin responsive($property, $value, $breakpoint: 500px) {
  @media screen and (max-width: $breakpoint) {
    #{$property}: $value;
  }
}

.cart {
  @include font-style(500, null);
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: $color-background;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .cartContent {
    padding: 20px;
    border-radius: 12px;
    background-color: $white-color;
    transform: scale(0.5);
    transition: 0.4s all;
    width: 100%;
    max-width: 560px;

    &.active {
      transform: scale(1);
    }

    @include responsive(padding, 10px);
  }
}

.logo {
  width: 96px;
  height: 16px;
}

.header {
  @include font-style(700, 22px);
  margin-top: 12px;

  @include responsive(margin-top, 8px);
  @include responsive(font-weight, 600);
  @include responsive(font-size, 16px);
}

.attention {
  @include font-style(400, 15px, $color-secondary);
  margin-top: 8px;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 12px;

  @include responsive(margin-top, 6px);
  @include responsive(font-size, 13px);
  @include responsive(gap, 8px);

  .warning {
    @include responsive(width, 20px);
  }
}

.chooseAll {
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 8px;

  @include responsive(margin, 10px 0);
  @include responsive(gap, 5px);
}

.chooseText {
  @include font-style(400, 14px, $color-text);
  cursor: pointer;
}

.checkbox {
  position: relative;
  border: 1.5px solid $color-secondary;
  border-radius: 3px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  min-width: 20px;
  height: 20px;
  width: 20px;
  appearance: none;
  opacity: 0.5;

  &:hover {
    opacity: 1;
    background: $white-color;

    @include responsive(opacity, 0.5);
  }

  &:checked {
    background-color: $color-secondary;
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    width: 4px;
    height: 10px;
    border: solid $white-color;
    border-width: 0 2px 2px 0;
    margin: -1px -1px 0 -1px;
    transform: rotate(45deg) translate(-50%, -50%);
    z-index: 2;
  }

  @include responsive(min-width, 20px);
  @include responsive(border, 1px solid $color-secondary);
}

.productsCart {
  display: flex;
  flex-direction: column;
  gap: 12px;

  @include responsive(gap, 8px);
}

.products,
.productsChecked {
  max-width: 560px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 16px;
  border: 2px solid $color-light-gray;
  border-radius: 8px;
  cursor: pointer;

  @include responsive(gap, 10px);
  @include responsive(padding, 10px);

  &:hover {
    border: 2px solid $hover-border;
    background: $hover-background;

    @include responsive(border, 2px solid $color-light-gray);
    @include responsive(background, $white-color);
  }

  &.productsChecked {
    background: $hover-background;
    border: 2px solid $hover-border;
  }
}

.productTitle {
  @include font-style(700, 16px);
  line-height: 125%;
  max-width: 375px;

  @include responsive(font-weight, 600);
  @include responsive(font-size, 14px);

  @media screen and (max-width: 330px) {
    font-size: 12px;
  }
}

.productDescription {
  @include font-style(400, 14px, $color-muted);
  line-height: 125%;
  max-width: 375px;
  margin-top: 8px;

  @include responsive(font-size, 12px);
  @include responsive(margin-top, 6px);
}

.productPrice,
.cartProdPrice {
  @include font-style(700, 16px);
  line-height: 125%;
  white-space: nowrap;

  @include responsive(font-weight, 600);
  @include responsive(font-size, 14px);

  @media screen and (max-width: 330px) {
    font-size: 12px;
  }
}

.priceRowC {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media screen and (max-width: 330px) {
    margin-top: 10px;
  }
}

.priceC {
  display: flex;
  align-items: center;
  gap: 16px;
}

.sumC {
  @include font-style(500, 14px, #383838);

  @include responsive(font-size, 13px);

  @media screen and (max-width: 330px) {
    font-size: 12px;
  }
}

.sumPriceC {
  @include font-style(700, 28px);

  @include responsive(font-size, 18px);

  @media screen and (max-width: 330px) {
    font-size: 15px;
  }
}

.sumPromoC {
  font-family: $font-family-main;
}

.oldSumC {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @include responsive(gap, 8px);
}

.saleSumC,
.saleC {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &.saleC {
    margin-top: 8px;
    gap: 20px;
    justify-content: space-between;

    @include responsive(gap, 10px);
    @include responsive(margin-top, 2px);
  }

  &.saleSumC {
    gap: 10px;
  }
}

.salePriceC {
  @include font-style(600, 14px, $color-secondary);

  @include responsive(font-size, 12px);

  @media screen and (max-width: 330px) {
    font-size: 11px;
  }
}

.btn,
.btnDisabled {
  width: 100%;
  border-radius: 6px;
  margin-top: 24px;
  height: 44px;
  font-size: 15px;
  font-family: $font-family-main;
  cursor: pointer;

  @include responsive(margin-top, 12px);

  &.btn {
    background: #6351f5;
    border-color: #6351f5;
    color: $white-color;
  }

  &.btnDisabled {
    background-color: $color-light-gray;
    border-color: $color-light-gray;
    color: $color-muted;
  }
}

.cartOldPrice {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-family: $font-family-main;
  margin-top: 8px;

  @include responsive(margin-top, 6px);
}

.oldPriceC {
  @include font-style(600, 14px, $color-muted);
  white-space: nowrap;
  text-decoration: none;
  position: relative;

  &:before {
    content: '';
    border-bottom: 1.5px solid $color-muted;
    position: absolute;
    width: 95%;
    height: 50%;
    transform: rotate(10deg);
  }

  @include responsive(font-size, 12px);

  @media screen and (max-width: 330px) {
    font-size: 11px;
  }
}

.priceDifferenceC {
  color: $white-color;
  font-weight: 600;
  font-size: 10px;
  padding: 2px 4px;
  background: #9b8eff;
  border-radius: 90px;
  height: 15px;
  white-space: nowrap;

  @include responsive(font-size, 9px);
  @include responsive(height, 14px);

  @media screen and (max-width: 330px) {
    font-size: 8px;
    height: 13px;
  }
}
