.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 16px;

  .code-invalid {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 8px;
  }

  .code-send-again {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
    margin-top: 10px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }
  }

  .timer {
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    margin-top: 10px;
  }

  .code-input-container {
    display: flex !important;
    align-items: center;
    gap: 16px;

    input {
      padding: 12px 8px;

      width: 40px;
      height: 44px;

      background: #f6f6f6;
      border: none;
      outline: none;
      border-radius: 4px;

      font-weight: 400;
      font-size: 24px;
      line-height: 20px;

      text-align: center;

      color: #c0c0c0;

      &:focus {
        color: #242424;
        background: #ffffff;
        border: 1px solid #9b8eff;
        box-shadow: 0px 0px 5px rgba(101, 83, 245, 0.3);
      }
      &:invalid {
        color: #f84708;
        background: #fff1ec;
      }
    }
  }
}
