.reset-password-page {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 380px;
    width: 100%;

    border-radius: 16px;

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }

    .description {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      text-align: center;
      margin: 28px 0;
    }

    .form-fields {
      display: flex;
      flex-direction: column;
      gap: 16px;
      min-width: 332px;
      width: 100%;

      .password-input-wrapper {
        position: relative;

        .input {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px 10px;
          gap: 4px;

          width: 100%;
          height: 40px;

          background: #f7f7f7;
          border-radius: 8px;
          border: none;
          outline: none;

          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #242424;

          &.password {
            padding: 12px 17px 12px 10px;
            max-width: 356px;
          }
          &:focus {
            background: #ffffff;
            box-shadow: 0px 0px 5px rgba(101, 83, 245, 0.3);
          }
          &.invalid {
            color: #f84708;
            background: rgba(248, 71, 8, 0.08);
            box-shadow: unset;
          }
          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #898989;
          }
        }

        .show-password-icon {
          position: absolute;
          top: 20px;
          right: 12px;
          cursor: pointer;
        }
      }
    }

    .code-invalid {
      align-self: flex-start;

      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-top: 8px;
    }

    .code {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 28px;
      margin-bottom: 30px;

      .code-title {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        width: 100%;
        margin-bottom: 28px;
      }
      .code-input-container {
        display: flex !important;
        align-items: center;
        gap: 16px;

        input {
          padding: 12px 8px;

          width: 40px;
          height: 44px;

          background: #f6f6f6;
          border: none;
          outline: none;
          border-radius: 4px;

          font-weight: 400;
          font-size: 24px;
          line-height: 20px;

          text-align: center;

          color: #c0c0c0;

          &:focus {
            color: #242424;
            background: #ffffff;
            border: 1px solid #9b8eff;
            box-shadow: 0px 0px 5px rgba(101, 83, 245, 0.3);
          }
        }

        &.invalid {
          input {
            color: #f84708;
            background: #ffffff;
            border: 1px solid #f84708;
          }
        }
      }
      .code-send-again {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        margin-top: 10px;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: default;
        }
      }
      .timer {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-top: 10px;
      }
    }

    .button-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-top: 30px;

      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      .login {
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        span {
          text-decoration: underline;
        }
      }
    }
  }
}
