$font-family-main: 'Manrope';
$font-family-secondary: 'Open Sans', Arial, sans-serif;

$color-primary: #6351f5;
$color-secondary: #6553f5;
$color-text: #242424;
$color-muted: #898989;
$color-grey: #606076;
$color-background: rgba(0, 0, 0, 0.4);
$color-light-gray: #e0e0e0;
$color-error: red;
$color-dark: #222222;
$white-color: #ffffff;

$hover-background: rgba(101, 83, 245, 0.1);
$hover-border: rgba(101, 83, 245, 0.25);

$border-radius: 6px;
$border-width: 2px;
$input-height: 44px;
$gap-large: 20px;
$gap-medium: 15px;
$gap-small: 10px;
$gap-xsmall: 5px;
