.wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;

  .check {
    width: 20px !important;
    height: 20px !important;
    border-radius: 4px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: '#FFFFFF';
    border: 2px solid #898989;

    &.active {
      border: none;
      background: '#6553F5';
    }
  }
}
