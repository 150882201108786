@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.wrapper {
  max-width: 510px;
  margin-top: 15px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 14px 20px -4px rgba(87, 87, 87, 0.1);
  gap: 16px;
  font-family: $font-family-main;

  border: 1.5px solid $color-secondary;

  @include responsive(500px) {
    padding: 12px;
    gap: 12px;
  }
}

.header {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  & img {
    width: 24px;
    height: 24px;
  }

  & p {
    color: $color-secondary;

    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;

    @include responsive(420px) {
      font-size: 16px;
    }

    @include responsive(371px) {
      font-size: 14px;
    }
  }
}

.content {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  background: #f7f7f9;

  @include responsive(500px) {
    padding: 8px 12px;
  }
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  gap: 16px;

  @include responsive(500px) {
    gap: 12px;
  }
}

.leftSide {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;

  @include responsive(500px) {
    gap: 12px;
  }
}

.img {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;
  flex-shrink: 0;

  @include responsive(500px) {
    width: 90px;
    height: 90px;
  }

  @include responsive(420px) {
    width: 75px;
    height: 75px;
  }
}

.title {
  color: $color-text;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;

  @include responsive(420px) {
    font-size: 14px;
  }
}

.price {
  color: $color-text;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  white-space: nowrap;

  @include responsive(420px) {
    font-size: 18px;
  }

  @include responsive(371px) {
    font-size: 16px;
  }
}

.divider {
  width: 100%;
  border: 1px dashed #dddde3;
}

.description {
  color: $color-grey;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  word-wrap: break-word;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
  width: 100%;
  height: 44px;
  border-radius: 10px;
  background: rgba(101, 83, 245, 0.08);
  border: none;
  color: $color-secondary;
  font-family: $font-family-main;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.checkboxInput {
  display: none;
}

.checkboxText {
  width: 100%;
  text-align: center;
}
