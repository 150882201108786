.modalThanks {
  font-family: 'Manrope';
  font-weight: 500;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.modalThanks.active {
  opacity: 1;
  pointer-events: all;
}

.modal__contentThanks {
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  transform: scale(0.5);
  transition: 0.4s all;
  width: 100%;
  max-width: 510px;
}
@media screen and (max-width: 330px) {
  .modal__contentThanks {
    padding: 10px;
  }
}

.modal__contentThanks.active {
  transform: scale(1);
}

.logoThanks {
  width: 96px;
  height: 16px;
}

.mainThanks {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
}
@media screen and (max-width: 500px) {
  .mainThanks {
    padding: 0 10px;
  }
}
@media screen and (max-width: 330px) {
  .mainThanks {
    padding: 0;
    gap: 10px;
  }
}

.headerThanks {
  font-size: 60px;
  font-weight: 700;
  color: #242424;
}
@media screen and (max-width: 500px) {
  .headerThanks {
    font-size: 40px;
  }
}

.titleThanks {
  font-size: 30px;
  font-weight: 700;
  color: #242424;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .titleThanks {
    font-size: 25px;
  }
}

.emailThanks {
  font-size: 21px;
  font-weight: 400;
  color: #4b564f;
  text-align: center;
}
@media screen and (max-width: 500px) {
  .emailThanks {
    font-size: 18px;
  }
}

.successLogo {
  margin: 20px 0;
  width: 100%;
  max-width: 150px;
}
@media screen and (max-width: 500px) {
  .successLogo {
    max-width: 100px;
    margin: 10px 0;
  }
}
@media screen and (max-width: 350px) {
  .successLogo {
    max-width: 70px;
    margin: 0;
  }
}

.footerThanks {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
}

.footerText {
  text-align: center;
}
@media screen and (max-width: 500px) {
  .footerText {
    font-size: 15px;
  }
}

.mainContent {
  display: flex;
  padding: 200px 0px 260px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  border-radius: 24px;
  background: #fff;
}

.mainBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.mainText {
  color: #242424;
  text-align: center;
  font-family: 'Manrope';
  font-size: 28px;
  font-weight: 600;
  line-height: 32px;
}

.subText {
  color: #616672;
  text-align: center;
  font-family: 'Manrope';
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.button {
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 15px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: none;
  background: #4285f4;

  color: #fff;
  text-align: center;
  font-family: 'Manrope';
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}
