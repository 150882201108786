@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.promo_balance {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: $color-text;

  @include responsive(430px) {
    margin-top: 10px;
  }

  input[type='radio'] {
    position: absolute;
    left: -9999px;

    & + label {
      position: relative;
      padding: 3px 0 0 30px;
      cursor: pointer;

      &::before {
        content: '';
        background: $white-color;
        border: 2px solid $color-primary;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 0;
      }

      &::after {
        content: '';
        background: $color-primary;
        height: 8.5px;
        width: 8.5px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        left: 5px;
        opacity: 0;
        transform: scale(2);
      }
    }

    &:checked + label::after {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.saleLabel {
  font-size: 15px;
  font-family: $font-family-main;

  @include responsive(330px) {
    font-size: 14px;
  }
}
