.signup-page {
  width: 100%;

  .container {
    .form-container {
      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 28px;

        margin-bottom: 28px;

        .title {
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
        }
        .subtitle {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          text-align: center;
          a {
            text-decoration: none;
            color: #4285f4;
          }
        }
      }
      .form-fields {
        display: flex;
        flex-direction: column;
        gap: 16px;
        min-width: 332px;
        width: 100%;
        margin-top: 28px;

        .input {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 10px 12px;
          gap: 4px;

          width: 100%;
          max-width: 356px;
          height: 40px;

          background: #f7f7f7;
          border-radius: 8px;
          border: none;
          outline: none;

          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #242424;

          &.password {
            padding: 12px 17px 12px 10px;
            max-width: 305px;
          }
          &:focus {
            background: #ffffff;
            box-shadow: 0px 0px 5px rgba(101, 83, 245, 0.3);
          }

          &.invalid {
            color: #f84708;
            background: rgba(248, 71, 8, 0.08);
            box-shadow: unset;
          }
          &::placeholder {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #898989;
          }
        }

        .phone-input-wrapper {
          margin-bottom: 28px;
          .phone-input {
            &.invalid {
              background: rgba(248, 71, 8, 0.08);
              box-shadow: unset;
              input {
                color: #f84708;
              }
            }
          }
        }

        .password-input-wrapper {
          position: relative;

          .show-password-icon {
            position: absolute;
            top: 20px;
            right: 12px;
            cursor: pointer;
          }
        }

        .code-invalid {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          margin-top: 8px;
        }
      }

      .form-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 14px;
        width: 100%;

        margin-bottom: 34px;
        .remember {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
        }
        .forgot-password {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          text-decoration: none;
        }
      }

      .via-email-steps {
        margin-bottom: 28px;
        .via-email-passwords {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      .terms {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 28px;

        width: 100%;

        .terms-text {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          a {
            color: #686868;
          }
        }
      }

      .recaptcha {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;
      }
    }
  }
}

.success-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
}
