body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.PhoneInput {
  padding: 8px 12px;
  background: #f6f6f6;
  border-radius: 4px;
  /* width: 100%; */
  display: flex;
  gap: 27px;
  height: 40px;
}
.PhoneInputCountry {
  margin: 0;
}
.PhoneInputCountryIcon {
  background: unset;
  box-shadow: none !important;
  border: none !important;
  margin-right: 8px;
}

.PhoneInputInput {
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  color: #898989;
  background-color: transparent;
  border: none;
  outline: none;
}
