@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.ofertaBox {
  margin-top: 10px;
}

.customCheckbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    align-items: center;
    user-select: none;

    &::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1.5px solid $color-primary;
      border-radius: 3px;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 143%;
    }

    &:hover::before {
      border-color: $color-primary;
    }

    &:active::before {
      background-color: $color-primary;
      border-color: transparent;
    }

    &:focus:not(:checked)::before {
      border-color: $color-primary;
    }
  }

  &:checked + label::before {
    border-color: transparent;
    background-color: white;
    background-image: url(../../../../../public/check.png);
  }
}

.oferta {
  font-size: 14px;

  @include responsive(500px) {
    font-size: 12px;
  }

  @include responsive(320px) {
    line-height: 120%;
  }
}

a {
  color: $color-secondary;
  text-decoration: none;

  &:hover {
    color: $color-secondary;
  }
}

.checkboxText {
  margin: -20px 0px 0px 30px;
  color: $color-text;
  font-size: 14px;
  font-weight: 500;
  font-family: '-apple-system', 'BlinkMacSystemFont';
}
