.main {
  width: 100%;
  max-width: 560px;
  margin-top: 10px;
  color: #242424;
  display: block;
  position: relative;

  @media screen and (max-width: 330px) {
    font-size: 14px;
    margin-top: 7px;
  }
}
.input {
  box-sizing: border-box;
  width: 100%;

  border-radius: 6px;
  height: 44px;
  border: 2px solid rgba(101, 83, 245, 0.25);
  margin-top: 5px;
  padding: 0 10px;

  font-size: 16px;
  font-weight: 400;

  color: #666;

  &::placeholder {
    color: #c0c0c0;
  }

  &:focus {
    border: 2px solid #6351f5;
    outline: none;
  }
}

.promoBtn,
.promoBtnCart {
  width: 100%;
  max-width: 132px;
  height: 32px;
  background: #6351f5;
  border-color: #6351f5;
  border-radius: 8px;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  font-family: 'Manrope';
  top: 29px;
  right: 5px;
  bottom: 0;
  cursor: pointer;
  position: absolute;

  @media screen and (max-width: 500px) {
    font-size: 14px;
    max-width: 115px;
  }

  &.promoBtnCart {
    top: 11px;

    @media screen and (max-width: 330px) {
      top: 9px;
    }

    &:disabled {
      background-color: #e0e0e0;
      border-color: #e0e0e0;
      color: #898989;
    }
  }

  @media screen and (max-width: 330px) {
    top: 30px;
  }
}

.promoError,
.promoSuc {
  font-family: 'Manrope';
  margin-top: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #6553f5;
  display: flex;
  align-items: center;
  gap: 12px;
}

.promoAct {
  font-weight: 700;
  font-size: 16px;
}
