@import '../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.modal {
  font-family: $font-family-secondary;
  font-weight: 500;
  min-height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  @include transition(opacity, 0.5s);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: $color-background;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  &__content {
    padding: 20px;
    border-radius: 12px;
    background-color: white;
    transform: scale(0.5);
    @include transition(all, 0.4s);
    width: 100%;
    max-width: 510px;

    &.active {
      transform: scale(1);
    }

    @include responsive(330px) {
      padding: 10px;
    }
  }
}

p {
  margin: 0;
  color: $color-text;

  @include responsive(500px) {
    font-size: 14px;
  }

  @include responsive(330px) {
    font-size: 13px;
  }
}

a {
  color: $color-secondary;
  text-decoration: none;

  &:hover {
    color: $color-secondary;
  }
}

.promoEr {
  font-family: $font-family-main;
  margin: 10px 0 0 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: $color-error;
  display: flex;
  align-items: center;
  gap: 12px;
}

.recDesc {
  margin-top: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: $color-muted;
  font-family: $font-family-main;
}

.divider {
  margin: 15px 0;
  width: 100%;
  border: 1px dashed #dddde3;
}
