@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.recurrent {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: $color-text;

  @include responsive(500px) {
    font-size: 10px;
  }
}
