.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 380px;
  width: 100%;

  .title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 28px;
  }

  .description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    margin: 0 0 16px;
  }

  .form-fields {
    display: flex;
    flex-direction: column;
    min-width: 332px;
    width: 100%;
    margin-bottom: 28px;

    .input {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 12px;
      gap: 4px;

      width: 100%;
      max-width: 355px;
      height: 40px;

      background: #f7f7f7;
      border-radius: 8px;
      border: none;
      outline: none;

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #242424;

      &:focus {
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(101, 83, 245, 0.3);
      }
      &.invalid {
        color: #f84708;
        background: rgba(248, 71, 8, 0.08);
        box-shadow: unset;
      }
      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #898989;
      }
    }

    .code-invalid {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      margin-top: 8px;
    }
  }

  .button-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .recaptcha {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-top: 30px;

    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    .login {
      cursor: pointer;
      border: none;
      outline: none;
      background: transparent;
      span {
        text-decoration: underline;
      }
    }
  }
}
