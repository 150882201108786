.tabs {
  display: flex;
  width: 100%;
  .tab {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 7px;
    width: 100%;

    background-color: transparent;
    color: #c0c0c0;
    outline: none;
    border: none;
    border-bottom: 2px solid #c0c0c0;

    &.active {
      color: #4285f4;
      border-bottom: 2px solid #4285f4;
    }
  }
}
