@import '../../styles/palette';

@mixin flex-center($justify: space-between, $align: center, $wrap: wrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}

.mainBalance {
  @include flex-center();
}

.userBalance,
.availableBalance {
  @include flex-center(flex-start, center, nowrap);
  gap: $gap-large;
  margin-top: 10px;
  font-family: $font-family-main;
  font-size: 14px;
  color: $color-muted;

  @media screen and (max-width: 500px) {
    gap: $gap-small;
  }
}

.availableBalance {
  gap: $gap-medium;

  @media screen and (max-width: 500px) {
    gap: $gap-xsmall;
  }
}

.balanceSum {
  color: $color-dark;
  font-weight: 600;
}

.explanation {
  cursor: pointer;
}

.balanceBtn {
  width: 100%;
  max-width: 132px;
  height: 32px;
  background: $color-primary;
  border-color: $color-primary;
  border-radius: 8px;
  text-align: center;
  color: $white-color;
  font-weight: 500;
  font-size: 16px;
  font-family: $font-family-main;
  cursor: pointer;

  @media screen and (max-width: 450px) {
    font-size: 14px;
    max-width: 115px;
  }

  @media screen and (max-width: 430px) {
    margin-top: 10px;
    font-size: 15px;
    max-width: 430px;
  }

  &:disabled {
    background-color: $color-light-gray;
    border-color: $color-light-gray;
    color: $color-muted;
  }
}

.balanceError,
.balanceSuc {
  font-family: $font-family-main;
  margin-top: 8px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: $color-primary;
  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
    gap: 8px;
  }

  @media screen and (max-width: 330px) {
    font-size: 13px;
    gap: 8px;
  }
}

.balanceAct {
  font-weight: 700;
}

input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  appearance: none;
  -moz-appearance: textfield;
}
