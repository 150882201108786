@import '../../../../styles/palette';

.button {
  margin-top: 15px;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background-color: $color-primary;
  border-color: $color-primary;
  color: $white-color;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    background-color: $color-primary;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $color-primary;
    border-color: $color-secondary;
  }
}

$dot: dot;
$dotBefore: dotBefore;
$dotAfter: dotAfter;

@keyframes dotBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dot {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.animation-dot-before {
  animation: dotBefore 1s infinite linear;
}

.animation-dot {
  animation: dot 1s infinite linear;
}

.animation-dot-after {
  animation: dotAfter 1s infinite linear;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dot,
.dotPrimary {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: $white-color;
  color: $white-color;
  animation: #{$dot} 1s infinite linear;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -20px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $white-color;
    color: $white-color;
    animation: #{$dotBefore} 1s infinite linear;
  }

  &::after {
    left: 20px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $white-color;
    color: $white-color;
    animation: #{$dotAfter} 1s infinite linear;
  }
}

.dotPrimary {
  background-color: $color-primary;
  color: $color-primary;

  &::before {
    background-color: $color-primary;
    color: $color-primary;
  }

  &::after {
    background-color: $color-primary;
    color: $color-primary;
  }
}
