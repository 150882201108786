@import '../../../../styles/palette';

@mixin transition($property, $duration) {
  transition: $property $duration;
}

@mixin responsive($max-width) {
  @media screen and (max-width: $max-width) {
    @content;
  }
}

.inputBox {
  width: 100%;
  margin-top: 12px;
  color: $color-text;
  display: flex;
  flex-direction: column;

  @include responsive(330px) {
    font-size: 14px;
    margin-top: 7px;
  }
}

.input {
  box-sizing: border-box;
  border-radius: 6px;
  border: 2px solid rgba($color-primary, 0.25);
  height: 44px;
  margin-top: 7px;
  color: $color-text;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 400;

  @include responsive(330px) {
    height: 40px;
  }

  &:disabled {
    border: 2px solid rgba($color-primary, 0.25);
    color: $color-text;
  }

  &:focus {
    border: 2px solid $color-primary;
    outline: none;
  }
}

.inputSuccess {
  background-image: url(../../../../../public/checkup.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98%;
}

.errorText {
  margin-top: 2px;
  color: $color-primary;
  font-size: 14px;
}
