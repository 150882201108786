.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px 36px;
  border-radius: 8px;
  outline: none;
  border: none;

  background-color: #1778fb;

  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;

  &.secondary {
    color: #1778fb;
    border: 1px solid #1778fb;
  }

  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    cursor: default;
    color: #898989;
    border: 1px solid #e9e9e9;
    background-color: #e9e9e9 !important;
    &:hover {
      opacity: 1;
    }
  }
}
