@import '../../../../styles/palette';

.button {
  margin-top: 15px;
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background-color: #eae9f1;
  border: 1.5px solid $color-primary;
  color: $color-primary;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}
